/** @jsxImportSource @emotion/react */
import Table from '../../components/Table/Table';
import { convertUtcToDateString } from '../../utils/date';
import { css, useTheme } from '@emotion/react';
import { getGuessResultMessage } from './guess_result_message';
import { useScreenSizeBreakpoints } from '../../hooks/useScreenSizeBreakpoints';
import { getBoldFontWeight } from '../../utils/text_formatting';

export type AttemptedGuessesType = {
    [guess: string]: {
        solveTime: number;
        isCorrect: boolean;
        partialAnswer: string;
    };
};

interface AttemptedGuessesTableProps {
    attemptedGuesses: AttemptedGuessesType;
    isMeta: boolean;
}

function AttemptedGuessesTable(props: AttemptedGuessesTableProps) {
    const { attemptedGuesses, isMeta } = props;
    const theme = useTheme();
    const screenSize = useScreenSizeBreakpoints();

    return (
        <Table
            columns={[
                { columnName: 'Time', widthPercentage: '20%' },
                { columnName: 'Guess', widthPercentage: '40%' },
                { columnName: 'Result', widthPercentage: '40%' },
            ]}
            data={Object.entries(attemptedGuesses).map(
                ([guess, { solveTime, isCorrect, partialAnswer }]) => {
                    const cssGuessText = css({
                        color: isCorrect
                            ? theme.colors.text.success
                            : !!partialAnswer
                            ? theme.colors.text.partial
                            : theme.colors.text.error,
                        fontWeight: getBoldFontWeight(screenSize),
                        wordBreak: 'break-word',
                    });
                    return {
                        content: {
                            Time: convertUtcToDateString(solveTime),
                            Guess: <div css={cssGuessText}>{guess}</div>,
                            Result: getGuessResultMessage(
                                guess,
                                isCorrect,
                                partialAnswer,
                                false /* wasPreviouslyGuessed */,
                                false /* hasAlreadyBeenSolved */,
                                isMeta,
                            ),
                        },
                    };
                },
            )}
        />
    );
}

export default AttemptedGuessesTable;
