/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import Leaderboard, { LeaderboardDataType } from './Leaderboard';
import { FunctionsContext } from '../..';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { useAuthUser } from '../../hooks/useAuthUser';
import { FunctionsError } from 'firebase/functions';
import { ADMIN_USERNAME, TESTSOLVE_USERNAME } from '../../data/puzzlehunt_details';
import { css } from '@emotion/react';

const CSS_CENTERED_TEXT = css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
});

function Teams() {
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardDataType>();
    const authUser = useAuthUser();
    const functions = useContext(FunctionsContext);

    const { callFunction: getLeaderboardDataFn, isRequestInProgress } =
        useFirebaseFunction('getLeaderboardData');

    useEffect(() => {
        if (!isRequestInProgress && leaderboardData === undefined) {
            getLeaderboardDataFn({})
                .then((getLeaderboardDataResponse) => {
                    setLeaderboardData(getLeaderboardDataResponse.data.leaderboardData);
                })
                .catch((error) => {
                    if ((error as FunctionsError).code === 'functions/not-found') {
                        setLeaderboardData({});
                    } else {
                        console.error('Error while loading leaderboard:', error);
                        setLeaderboardData({});
                    }
                });
        }
    }, [functions, getLeaderboardDataFn, isRequestInProgress, leaderboardData]);

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.TEAMS}</Title>
            {leaderboardData &&
            Object.keys(leaderboardData).filter((teamUsername) => {
                return (
                    teamUsername !== ADMIN_USERNAME && !teamUsername.includes(TESTSOLVE_USERNAME)
                );
            }).length > 0 ? (
                <Leaderboard
                    leaderboardData={leaderboardData}
                    currentTeamUsername={authUser?.uid ? authUser.uid : undefined}
                />
            ) : (
                <div css={CSS_CENTERED_TEXT}>
                    {leaderboardData ? 'No teams registered yet.' : 'Loading...'}
                </div>
            )}
        </PageLayout>
    );
}

export default Teams;
