/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PageLayout from '../../../components/PageLayout/PageLayout';
import Title from '../../../components/PageLayout/Title';
import PageWithTableOfContents from '../../../components/PageWithTableOfContents/PageWithTableOfContents';
import { PAGE_NAMES } from '../../../data/page_structure';
import TeamInfo from './TeamInfo';
import ChangeTeamName from './ChangeTeamName';
import ChangePassword from './ChangePassword';
import EditTeamMembers from './EditTeamMembers';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../../utils/content_page_css';
import { css } from '@emotion/react';

const CSS_ACCOUNT_PAGE_CONTENT = css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
});

enum AccountPageType {
    TEAM_INFO = 'Team Info',
    CHANGE_TEAM_NAME = 'Change Team Name',
    CHANGE_PASSWORD = 'Change Password',
    EDIT_TEAM_MEMBERS = 'Edit Team Members',
}

function AccountPage() {
    const [accountPageType, setAccountPageType] = useState(AccountPageType.TEAM_INFO);

    return (
        <PageLayout maxWidth="1000px">
            <Title>{PAGE_NAMES.ACCOUNT}</Title>
            <PageWithTableOfContents
                contents={[
                    {
                        text: AccountPageType.TEAM_INFO,
                        onClick: () => setAccountPageType(AccountPageType.TEAM_INFO),
                    },
                    {
                        text: AccountPageType.CHANGE_TEAM_NAME,
                        onClick: () => setAccountPageType(AccountPageType.CHANGE_TEAM_NAME),
                    },
                    {
                        text: AccountPageType.CHANGE_PASSWORD,
                        onClick: () => setAccountPageType(AccountPageType.CHANGE_PASSWORD),
                    },
                    {
                        text: AccountPageType.EDIT_TEAM_MEMBERS,
                        onClick: () => setAccountPageType(AccountPageType.EDIT_TEAM_MEMBERS),
                    },
                ]}
                selectedItem={accountPageType}
            >
                <div css={[CSS_IGNORE_TOP_CONTENT_PADDING, CSS_ACCOUNT_PAGE_CONTENT]}>
                    <h2>{accountPageType}</h2>
                    <div>
                        {accountPageType === AccountPageType.TEAM_INFO && <TeamInfo />}
                        {accountPageType === AccountPageType.CHANGE_TEAM_NAME && <ChangeTeamName />}
                        {accountPageType === AccountPageType.CHANGE_PASSWORD && <ChangePassword />}
                        {accountPageType === AccountPageType.EDIT_TEAM_MEMBERS && (
                            <EditTeamMembers />
                        )}
                    </div>
                </div>
            </PageWithTableOfContents>
        </PageLayout>
    );
}

export default AccountPage;
