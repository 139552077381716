/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { ADMIN_USERNAME, TESTSOLVE_USERNAME } from '../../data/puzzlehunt_details';
import Table from '../../components/Table/Table';
import { useNavigate } from 'react-router';
import { convertToPathName, joinPaths } from '../../utils/url';
import { PAGE_NAMES } from '../../data/page_structure';

export type AllTeamsType = { username: string; displayName: string }[];

interface AdminTableProps {
    allTeams: AllTeamsType;
}

function AdminTable(props: AdminTableProps) {
    const { allTeams } = props;
    const navigate = useNavigate();

    const sortedTeams = useMemo(() => {
        return (
            allTeams
                // Remove the admin username because it's reserved for the puzzlehunt organizers.
                .filter(({ username }) => {
                    return username !== ADMIN_USERNAME && !username.includes(TESTSOLVE_USERNAME);
                })
                .sort((a, b) => {
                    return (
                        // Sort username alphabetically.
                        a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1
                    );
                })
        );
    }, [allTeams]);

    const onRowClick = useCallback(
        (username: string, displayName: string) => {
            navigate(
                `${joinPaths(
                    convertToPathName(PAGE_NAMES.ADMIN),
                    encodeURIComponent(username),
                )}?displayName=${encodeURIComponent(displayName)}`,
            );
        },
        [navigate],
    );

    return (
        <Table
            columns={[
                { columnName: 'Username', widthPercentage: '20%' },
                { columnName: 'Display name', widthPercentage: '80%' },
            ]}
            data={sortedTeams.map(({ username, displayName }) => {
                return {
                    content: {
                        Username: username,
                        'Display name': displayName,
                    },
                    onClick: () => onRowClick(username, displayName),
                };
            })}
        />
    );
}

export default AdminTable;
