/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useParams, useSearchParams } from 'react-router-dom';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import Row from '../../components/Form/Row';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { SolvedPuzzleDataType } from '../../components/PuzzleDisplay/SolvedPuzzlesTable/SolvedPuzzlesTable';
import { useCurrentPuzzleSections } from '../../hooks/useCurrentPuzzleSections';
import Table from '../../components/Table/Table';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import ActionButton, { ButtonState, ButtonType } from '../../components/ActionButton/ActionButton';
import { SectionType } from '../../data/puzzles/puzzle_defs';
import { FEEDER_HINT_POINTS, META_HINT_POINTS } from '../../data/puzzlehunt_details';
import { useUnlockHint } from '../../hooks/useUnlockHint';

const CSS_TEAM_INFO_ITEM = css({
    fontSize: '24px',
});

const CSS_UNLOCK_HINT = css({
    display: 'flex',
    gap: '18px',
    alignItems: 'center',
});

const CSS_UNLOCK_HINT_TEXT = css({
    minWidth: '28px',
});

function AdminTeamPage() {
    const { teamUsername } = useParams();
    const [searchParams] = useSearchParams();
    const [solvedPuzzleData, setSolvedPuzzleData] = useState<SolvedPuzzleDataType | undefined>();
    const [score, setScore] = useState(0);
    const [unlockedHints, setUnlockedHints] = useState<{
        [puzzleName: string]: { value: number; unlockTime: number };
    }>();
    const [usedHintVoucher, setUsedHintVoucher] = useState(false);
    const { puzzleSections } = useCurrentPuzzleSections();
    const theme = useTheme();

    const {
        callFunction: getSolvedPuzzlesFn,
        isRequestInProgress: isGetSolvedPuzzlesRequestInProgress,
    } = useFirebaseFunction('getSolvedPuzzles');
    const { unlockHint, isUnlockHintsRequestInProgress } = useUnlockHint(teamUsername ?? '');

    const loadPuzzleState = useCallback(
        (username: string) => {
            if (!isGetSolvedPuzzlesRequestInProgress) {
                getSolvedPuzzlesFn({
                    username: username,
                })
                    .then((getSolvedPuzzlesResponse) => {
                        const solvedPuzzles = getSolvedPuzzlesResponse.data.solvedPuzzles;
                        const sortedSolvedPuzzles = Object.fromEntries(
                            Object.entries(solvedPuzzles).sort(
                                (a, b) => b[1].solveTime - a[1].solveTime,
                            ),
                        );
                        setSolvedPuzzleData(sortedSolvedPuzzles);
                        setScore(getSolvedPuzzlesResponse.data.score);
                        setUnlockedHints(getSolvedPuzzlesResponse.data.unlockedHints);
                        setUsedHintVoucher(getSolvedPuzzlesResponse.data.usedHintVoucher);
                    })
                    .catch((error) => {
                        console.error(
                            `Error while loading puzzle info in admin view for team ${username}: ${error}`,
                        );
                        setSolvedPuzzleData({});
                    });
            }
        },
        [getSolvedPuzzlesFn, isGetSolvedPuzzlesRequestInProgress],
    );

    useEffect(() => {
        if (teamUsername && solvedPuzzleData === undefined) {
            loadPuzzleState(teamUsername);
        }
    }, [teamUsername, loadPuzzleState, solvedPuzzleData]);

    const allPuzzles = useMemo(() => {
        if (!puzzleSections) {
            return puzzleSections;
        }
        return Object.values(puzzleSections)
            .map((section: SectionType) => {
                if ('puzzles' in section) {
                    return section.puzzles.map(({ name, isMeta }) => {
                        return { name, points: section.points, isMeta };
                    });
                }

                return [];
            })
            .flat();
    }, [puzzleSections]);

    return (
        <PageLayout>
            <Title backButtonLink={PAGE_TO_URL[PAGE_NAMES.ADMIN]}>Team Settings</Title>
            <div css={[CSS_IGNORE_TOP_CONTENT_PADDING]}>
                <Row>
                    <div css={CSS_TEAM_INFO_ITEM}>
                        Username: <b>{teamUsername}</b>
                    </div>
                </Row>
                <Row>
                    <div css={CSS_TEAM_INFO_ITEM}>
                        Team name: <b>{searchParams.get('displayName')}</b>
                    </div>
                </Row>
                <Row>
                    <div css={CSS_TEAM_INFO_ITEM}>
                        Score: <b>{score ?? 'Loading...'}</b>
                    </div>
                </Row>
                <Row>
                    <div>Used hint voucher: {usedHintVoucher ? 'Yes' : 'No'}</div>
                </Row>
                <Table
                    columns={[
                        { columnName: 'Puzzle name', widthPercentage: '40%' },
                        { columnName: 'Points', widthPercentage: '10%' },
                        { columnName: 'Solved?', widthPercentage: '10%' },
                        { columnName: 'Hints unlocked?', widthPercentage: '40%' },
                    ]}
                    data={(allPuzzles ?? []).map(({ name, points, isMeta }) => {
                        return {
                            content: {
                                'Puzzle name': <>{name}</>,
                                Points: <>{points}</>,
                                'Solved?': (
                                    <>
                                        {solvedPuzzleData && name in solvedPuzzleData ? (
                                            <div style={{ color: theme.colors.text.success }}>
                                                Yes
                                            </div>
                                        ) : (
                                            <div style={{ color: theme.colors.text.error }}>No</div>
                                        )}
                                    </>
                                ),
                                'Hints unlocked?': (
                                    <>
                                        {unlockedHints !== undefined ? (
                                            <div css={CSS_UNLOCK_HINT}>
                                                <div css={CSS_UNLOCK_HINT_TEXT}>
                                                    {unlockedHints && name in unlockedHints ? (
                                                        <div
                                                            style={{
                                                                color: theme.colors.text.success,
                                                            }}
                                                        >
                                                            Yes
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                color: theme.colors.text.error,
                                                            }}
                                                        >
                                                            No
                                                        </div>
                                                    )}
                                                </div>
                                                {!(unlockedHints && name in unlockedHints) && (
                                                    <ActionButton
                                                        // Hard-coding width so that the width of button stays the same when disabled.
                                                        width="111px"
                                                        buttonType={ButtonType.PRIMARY}
                                                        onClick={() =>
                                                            unlockHint(
                                                                name,
                                                                isMeta
                                                                    ? META_HINT_POINTS
                                                                    : FEEDER_HINT_POINTS,
                                                                !!isMeta,
                                                                () => {
                                                                    loadPuzzleState(
                                                                        teamUsername ?? '',
                                                                    );
                                                                },
                                                                (error) => {
                                                                    console.error(
                                                                        'Error while loading hints:',
                                                                        error,
                                                                    );
                                                                },
                                                            )
                                                        }
                                                        buttonStateOverride={
                                                            isUnlockHintsRequestInProgress
                                                                ? ButtonState.DISABLED
                                                                : ButtonState.DEFAULT
                                                        }
                                                    >
                                                        {isUnlockHintsRequestInProgress
                                                            ? 'Loading...'
                                                            : 'Unlock hint'}
                                                    </ActionButton>
                                                )}
                                            </div>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </>
                                ),
                            },
                        };
                    })}
                />
            </div>
        </PageLayout>
    );
}

export default AdminTeamPage;
