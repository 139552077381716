import { useCallback, useEffect, useState } from 'react';
import { useFirebaseFunction } from './useFirebaseFunction';
import { HttpsCallableResult } from 'firebase/functions';
import { isPuzzlehuntOver } from '../utils/flagging';

export function useUnlockHint(initialUsername?: string) {
    const [username, setUsername] = useState(initialUsername);
    const [isHintFree, setIsHintFree] = useState<boolean>();
    const { callFunction: getHintScoreFn, isRequestInProgress: isgetHintScoreRequestInProgress } =
        useFirebaseFunction('getHintScore');
    const { callFunction: unlockHintsFn, isRequestInProgress: isUnlockHintsRequestInProgress } =
        useFirebaseFunction('unlockHint');

    useEffect(() => {
        if (!username) {
            return;
        }
        if (
            !isgetHintScoreRequestInProgress &&
            !isUnlockHintsRequestInProgress &&
            isHintFree === undefined
        ) {
            getHintScoreFn({
                username: username,
            })
                .then((getHintScoreResponse) => {
                    setIsHintFree(getHintScoreResponse.data.isFree);
                })
                .catch((error) => {
                    console.error('Error while attempting to get hint score:', error);
                });
        }
    }, [
        getHintScoreFn,
        isHintFree,
        isUnlockHintsRequestInProgress,
        isgetHintScoreRequestInProgress,
        username,
    ]);

    const unlockHint = useCallback(
        (
            puzzleName: string,
            hintPointValue: number,
            isMeta: boolean,
            successCb: (response: HttpsCallableResult<{ hint: string }>) => void,
            errorCb: (error: any) => void,
        ) => {
            if (!isgetHintScoreRequestInProgress && !isUnlockHintsRequestInProgress) {
                let pointValue = hintPointValue;
                if (isHintFree && !isMeta) {
                    pointValue = 0;
                }
                unlockHintsFn({
                    username: username ?? '',
                    puzzleName: puzzleName,
                    pointValue: pointValue,
                    isMeta,
                    isOfficial: !isPuzzlehuntOver(),
                })
                    .then((response) => {
                        setIsHintFree(false);
                        successCb(response);
                    })
                    .catch(errorCb);
            }
        },
        [
            isHintFree,
            isUnlockHintsRequestInProgress,
            isgetHintScoreRequestInProgress,
            unlockHintsFn,
            username,
        ],
    );

    return { isHintFree, setUsername, unlockHint, isUnlockHintsRequestInProgress };
}
