/** @jsxImportSource @emotion/react */
import SolvedMetaWrapper from '../../components/PuzzleDisplay/SolvedMetaWrapper';
import Table from '../../components/Table/Table';
import { convertUtcToDateString } from '../../utils/date';
import { ADMIN_USERNAME, TESTSOLVE_USERNAME } from '../../data/puzzlehunt_details';
import { css } from '@emotion/react';
import { useMemo } from 'react';

export type LeaderboardDataType = {
    [teamUsername: string]: {
        teamName: string;
        score: number;
        isMetaSolved: boolean;
        lastSolvedTime: number;
    };
};

const CSS_TEAM_NAME = css({
    wordBreak: 'break-word',
});

interface LeaderboardProps {
    leaderboardData: LeaderboardDataType;
    /**
     * This is the unique team username/ID and not the team display name.
     */
    currentTeamUsername?: string;
}

function Leaderboard(props: LeaderboardProps) {
    const { leaderboardData, currentTeamUsername } = props;

    const sortedLeaderboardData = useMemo(() => {
        return (
            Object.entries(leaderboardData)
                // Remove the admin username because it's reserved for the puzzlehunt organizers.
                .filter(([teamUsername, _]) => {
                    return (
                        teamUsername !== ADMIN_USERNAME &&
                        !teamUsername.includes(TESTSOLVE_USERNAME)
                    );
                })
                .sort((a, b) => {
                    return (
                        // First, sort by score.
                        b[1].score - a[1].score ||
                        // If scores are the same, sort by last solve time.
                        a[1].lastSolvedTime - b[1].lastSolvedTime ||
                        // If all else are the same, sort team name alphabetically.
                        (a[1].teamName.toLowerCase() < b[1].teamName.toLowerCase() ? -1 : 1)
                    );
                })
        );
    }, [leaderboardData]);

    return (
        <Table
            columns={[
                { columnName: 'Rank', widthPercentage: '10%' },
                { columnName: 'Team', widthPercentage: '60%' },
                { columnName: 'Score', widthPercentage: '10%' },
                { columnName: 'Last Solved', widthPercentage: '20%' },
            ]}
            data={sortedLeaderboardData.map(
                ([_, { teamName, score, isMetaSolved, lastSolvedTime }], index) => {
                    return {
                        content: {
                            Rank: (index + 1).toString(),
                            Team: isMetaSolved ? (
                                <SolvedMetaWrapper>
                                    <div css={CSS_TEAM_NAME}>{teamName}</div>
                                </SolvedMetaWrapper>
                            ) : (
                                <div css={CSS_TEAM_NAME}>{teamName}</div>
                            ),
                            Score: score.toString(),
                            'Last Solved': lastSolvedTime ? (
                                convertUtcToDateString(lastSolvedTime)
                            ) : (
                                <></>
                            ),
                        },
                    };
                },
            )}
            emphasizedRowIndex={Object.keys(sortedLeaderboardData).findIndex(
                (teamUsername) => teamUsername === currentTeamUsername,
            )}
        />
    );
}

export default Leaderboard;
