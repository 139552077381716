/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { PuzzleInfo } from '../../../data/puzzles/puzzle_defs';
import ActionButton, { ButtonType } from '../../ActionButton/ActionButton';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check_circle.svg';
import { ReactComponent as OpenInNewIcon } from '../../../assets/icons/open_in_new.svg';
import { useState } from 'react';
import { convertToPathName, joinPaths } from '../../../utils/url';
import Tooltip from '../../Tooltip/Tooltip';
import { PUZZLE_PAGE_PREPEND_URL } from '../../../data/page_structure';
import { PuzzleStats, getPuzzleStatsString } from '../../../utils/puzzle_stats';
import {
    ScreenSize,
    isSmallerThanOrEqual,
    useScreenSizeBreakpoints,
} from '../../../hooks/useScreenSizeBreakpoints';
import LinkableRowContent from '../../Table/CustomRow/LinkableRowContent';
import Row from '../../Table/CustomRow/Row';
import { isPuzzlehuntOver } from '../../../utils/flagging';

interface PuzzleRowProps {
    puzzleInfo: PuzzleInfo;
    /**
     * Puzzle stats regarding number of guesses and solves for each puzzle.
     */
    puzzleStats?: PuzzleStats;
    isArchived?: boolean;
    isCompleted?: boolean;
}

function PuzzleRow(props: PuzzleRowProps) {
    const { puzzleInfo, puzzleStats, isArchived, isCompleted } = props;
    const [isHovered, setIsHovered] = useState(false);
    const screenSize = useScreenSizeBreakpoints();
    const theme = useTheme();

    const shouldCondenseTable = isSmallerThanOrEqual(screenSize, ScreenSize.MEDIUM);
    const showSolution = isArchived && puzzleInfo.solutionLink;
    // Only have a puzzle page for the current hunt. Otherwise, include solution in-line in the row.
    const puzzleLink = isArchived
        ? puzzleInfo.link
        : joinPaths(PUZZLE_PAGE_PREPEND_URL, convertToPathName(puzzleInfo.name));
    const cssRightButtons = css({
        alignItems: 'center',
        display: 'flex',
        gap: '12px',
        padding: '4px 10px 4px 0px',
    });
    const cssPuzzleLinkHover = css({
        '&:hover': {
            backgroundColor: theme.colors.hover,
        },
    });
    const cssRow = css({
        display: 'flex',
        width: '100%',
    });
    const cssRowContent = css({
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
    });
    const cssCheckCircleIcon = css({
        fill: theme.colors.text.success,
        visibility: isCompleted ? 'visible' : 'hidden',
    });
    const cssRowContentInner = css({
        display: 'flex',
        flexDirection: 'column',
    });
    const cssPuzzleStats = css({
        color: theme.colors.text.dark,
        fontWeight: 400,
    });

    return (
        <Row>
            <div css={[cssRow, isHovered ? cssPuzzleLinkHover : {}]}>
                <LinkableRowContent
                    link={puzzleLink}
                    setIsHovered={setIsHovered}
                    isHovered={isHovered}
                >
                    <div css={cssRowContent}>
                        <CheckCircleIcon css={cssCheckCircleIcon} width="18px" height="18px" />
                        <div css={cssRowContentInner}>
                            <div>
                                {isArchived
                                    ? puzzleInfo.name
                                    : puzzleInfo.displayName || puzzleInfo.name}
                            </div>
                            {puzzleStats && shouldCondenseTable && (
                                <div css={cssPuzzleStats}>{getPuzzleStatsString(puzzleStats)}</div>
                            )}
                        </div>
                    </div>
                </LinkableRowContent>
                <div css={cssRightButtons}>
                    {puzzleStats && !shouldCondenseTable && (
                        <div css={cssPuzzleStats}>{getPuzzleStatsString(puzzleStats)}</div>
                    )}
                    {(showSolution || isPuzzlehuntOver()) && puzzleInfo.solutionLink && (
                        <ActionButton
                            link={puzzleInfo.solutionLink}
                            buttonType={ButtonType.PRIMARY}
                        >
                            Solution
                        </ActionButton>
                    )}
                    {!isArchived && !isPuzzlehuntOver() && (
                        <Tooltip tooltip="Open Puzzle PDF">
                            <ActionButton
                                link={puzzleInfo.link}
                                buttonType={ButtonType.PRIMARY}
                                isIcon
                                ariaLabel={`Open ${puzzleInfo.name} puzzle PDF (opens in a new tab)`}
                            >
                                <OpenInNewIcon width="18px" height="18px" />
                            </ActionButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        </Row>
    );
}

export default PuzzleRow;
