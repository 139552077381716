/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import { CURRENT_YEAR } from '../../data/puzzlehunt_details';

const CSS_TEXT = css({
    textAlign: 'center',
});

function RegistrationClosedPage() {
    return (
        <PageLayout maxWidth="600px">
            <Title backButtonLink={PAGE_TO_URL[PAGE_NAMES.LOGIN]}>{PAGE_NAMES.REGISTER}</Title>
            <div css={CSS_TEXT}>
                Sorry, registration has closed for Holiday Hoopla {CURRENT_YEAR}.
            </div>
        </PageLayout>
    );
}

export default RegistrationClosedPage;
