import { META_SOLUTION_RESPONSE_TEXT } from '../../data/puzzlehunt_details';

export function getGuessResultMessage(
    guess: string,
    isCorrect: boolean,
    partialAnswer: string,
    wasPreviouslyGuessed: boolean,
    hasAlreadyBeenSolved: boolean,
    isMeta: boolean,
) {
    if (hasAlreadyBeenSolved) {
        return "You've already solved this puzzle!";
    }
    const wasPreviouslyGuessedString = wasPreviouslyGuessed
        ? ', but you already guessed this before'
        : '';

    if (isCorrect) {
        if (isMeta) {
            return META_SOLUTION_RESPONSE_TEXT.replace('{}', guess);
        } else {
            return `${guess} is correct${wasPreviouslyGuessedString}!`;
        }
    } else if (partialAnswer) {
        return partialAnswer;
    } else {
        return `Incorrect${wasPreviouslyGuessedString}!`;
    }
}
