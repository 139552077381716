import { httpsCallable } from 'firebase/functions';
import { useCallback, useContext, useState } from 'react';
import { FunctionsContext } from '..';
import { TeamMembersType } from '../components/EditTeam/edit_team_requirements';
import { SolvedPuzzleDataType } from '../components/PuzzleDisplay/SolvedPuzzlesTable/SolvedPuzzlesTable';
import { LeaderboardDataType } from '../pages/Teams/Leaderboard';
import { AllPuzzleStats } from '../utils/puzzle_stats';
import { AttemptedGuessesType } from '../pages/Puzzles/AttemptedGuessesTable';
import { ErrataType } from '../pages/Puzzles/ErrataTable';
import { AllTeamsType } from '../pages/Admin/AdminTable';
// TODO: Move all types above outside of the components and into def files.

type FunctionTypes = {
    register: {
        RequestType: {
            username: string;
            displayName: string;
            password: string;
            teamMembers: { name: string; email: string }[];
        };
        ResponseType: {
            token: string;
        };
    };
    login: {
        RequestType: {
            username: string;
            password: string;
        };
        ResponseType: {
            token: string;
            teamName: string;
        };
    };
    resetPassword: {
        RequestType: {
            username: string;
        };
        ResponseType: {};
    };
    updatePassword: {
        RequestType: {
            username: string;
            oldPassword: string;
            newPassword: string;
        };
        ResponseType: {};
    };
    updateTeamName: {
        RequestType: { username: string; teamName: string; password: string };
        ResponseType: {};
    };
    updateTeamMembers: {
        RequestType: { username: string; teamMembers: TeamMembersType };
        ResponseType: {};
    };
    getTeamMembers: {
        RequestType: {
            username: string;
        };
        ResponseType: {
            teamMembers: TeamMembersType;
        };
    };
    getLeaderboardData: {
        RequestType: {};
        ResponseType: {
            leaderboardData: LeaderboardDataType;
        };
    };
    getAllEmails: {
        RequestType: {
            username: string;
        };
        ResponseType: {
            emails: string[];
        };
    };
    getAllTeams: {
        RequestType: {
            username: string;
        };
        ResponseType: {
            allTeams: AllTeamsType;
        };
    };
    getPuzzleStats: {
        RequestType: {};
        ResponseType: {
            puzzleStats: AllPuzzleStats;
        };
    };
    getAttemptedGuesses: {
        RequestType: {
            username: string;
            puzzleName: string;
        };
        ResponseType: {
            attemptedGuesses: AttemptedGuessesType;
        };
    };
    getErrata: {
        RequestType: {
            puzzleName: string;
        };
        ResponseType: {
            errata: ErrataType;
        };
    };
    getSolvedPuzzles: {
        RequestType: {
            username: string;
        };
        ResponseType: {
            solvedPuzzles: SolvedPuzzleDataType;
            score: number;
            unlockedHints: {
                [puzzleName: string]: {
                    value: number;
                    unlockTime: number;
                };
            };
            usedHintVoucher: boolean;
        };
    };
    checkPuzzleAnswer: {
        RequestType: {
            username: string;
            puzzleName: string;
            guess: string;
            points: number;
            isMeta: boolean;
            isOfficial: boolean;
        };
        ResponseType: {
            isCorrect: boolean;
            wasPreviouslyGuessed: boolean;
            hasAlreadyBeenSolved: boolean;
            partialAnswer?: string;
        };
    };
    getPuzzleHint: {
        RequestType: {
            username: string;
            puzzleName: string;
        };
        ResponseType: {
            hintExists: boolean;
            hint: string;
        };
    };
    unlockHint: {
        RequestType: {
            username: string;
            puzzleName: string;
            pointValue: number;
            isMeta: boolean;
            isOfficial: boolean;
        };
        ResponseType: {
            hint: string;
        };
    };
    getHintScore: {
        RequestType: {
            username: string;
        };
        ResponseType: {
            isFree: boolean;
        };
    };
};

export function useFirebaseFunction<T extends keyof FunctionTypes>(functionName: T) {
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const functions = useContext(FunctionsContext);

    const callFunction = useCallback(
        async (inputData: FunctionTypes[T]['RequestType']) => {
            const firebaseFunction = httpsCallable<
                FunctionTypes[T]['RequestType'],
                FunctionTypes[T]['ResponseType']
            >(functions, functionName);
            setIsRequestInProgress(true);
            try {
                const registerResponse = await firebaseFunction(inputData);
                setIsRequestInProgress(false);
                return registerResponse;
            } catch (error) {
                setIsRequestInProgress(false);
                throw error;
            }
        },
        [functionName, functions],
    );

    return { callFunction, isRequestInProgress };
}
