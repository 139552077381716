/** @jsxImportSource @emotion/react */
import { useLocation } from 'react-router-dom';

import ActionButton, { ButtonState, ButtonType } from '../ActionButton/ActionButton';
import { PAGE_NAMES, PAGE_TO_URL, isPathUnderPageOption } from '../../data/page_structure';
import { useAuthUser } from '../../hooks/useAuthUser';
import { isPageAccessible } from '../../utils/flagging';
import { css } from '@emotion/react';
import DropdownMenuItem, { MenuItemType } from '../DropdownMenu/DropdownMenuItem';
import HeaderMenu from './HeaderMenu';

const CSS_NAVIGATION_BAR = css({
    display: 'flex',
    flexDirection: 'row',
    listStyleType: 'none',
    margin: '0px',
});

interface NavigationOptionButtonProps {
    pageName: string;
    isMenu?: boolean;
    adminOnly?: boolean;
    activePageOnly?: boolean;
}

function NavigationOptionButton(props: NavigationOptionButtonProps) {
    const { pageName, isMenu, adminOnly, activePageOnly } = props;
    const authUser = useAuthUser();
    const location = useLocation();

    const isOptionSelected = isPathUnderPageOption(location.pathname, pageName);

    return isPageAccessible(authUser, pageName, adminOnly ?? false, activePageOnly ?? false) ? (
        <li>
            {isMenu ? (
                <DropdownMenuItem
                    key={pageName}
                    link={PAGE_TO_URL[pageName]}
                    menuItemType={MenuItemType.PRIMARY_BACKGROUND}
                    isSelected={isOptionSelected}
                >
                    {pageName}
                </DropdownMenuItem>
            ) : (
                <ActionButton
                    key={pageName}
                    link={PAGE_TO_URL[pageName]}
                    buttonType={ButtonType.INVISIBLE}
                    buttonStateOverride={
                        isOptionSelected ? ButtonState.SELECTED : ButtonState.DEFAULT
                    }
                >
                    {pageName}
                </ActionButton>
            )}
        </li>
    ) : (
        <></>
    );
}

interface NavigationOptionsContentProps {
    isMenu?: boolean;
}

function NavigationOptionsContent(props: NavigationOptionsContentProps) {
    const { isMenu } = props;
    const authUser = useAuthUser();
    return (
        <>
            <NavigationOptionButton pageName={PAGE_NAMES.RULES_AND_INFO} isMenu={isMenu} />
            <NavigationOptionButton pageName={PAGE_NAMES.ANNOUNCEMENTS} isMenu={isMenu} />
            <NavigationOptionButton pageName={PAGE_NAMES.STORY} isMenu={isMenu} />
            <NavigationOptionButton pageName={PAGE_NAMES.PUZZLES} isMenu={isMenu} />
            <NavigationOptionButton pageName={PAGE_NAMES.ADMIN} isMenu={isMenu} adminOnly />
            <NavigationOptionButton pageName={PAGE_NAMES.TEAMS} isMenu={isMenu} activePageOnly />
            <NavigationOptionButton pageName={PAGE_NAMES.ARCHIVE} isMenu={isMenu} />
            <NavigationOptionButton pageName={PAGE_NAMES.ABOUT_US} isMenu={isMenu} />
            {!!authUser?.uid ? (
                <NavigationOptionButton pageName={PAGE_NAMES.ACCOUNT} isMenu={isMenu} />
            ) : (
                <NavigationOptionButton pageName={PAGE_NAMES.LOGIN} isMenu={isMenu} />
            )}
        </>
    );
}

interface NavigationOptionsProps {
    isMenu?: boolean;
    menuRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Navigation options shown in the top right of the page.
 * The buttons/dropdown menus are programatically generated from the hard-coded data.
 */
function NavigationOptions(props: NavigationOptionsProps) {
    const { isMenu, menuRef } = props;
    return (
        <nav>
            <ul css={CSS_NAVIGATION_BAR}>
                {isMenu ? (
                    <HeaderMenu ref={menuRef}>
                        <NavigationOptionsContent isMenu />
                    </HeaderMenu>
                ) : (
                    <NavigationOptionsContent />
                )}
            </ul>
        </nav>
    );
}

export default NavigationOptions;
