/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { FunctionsContext } from '../..';
import { FunctionsError } from 'firebase/functions';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import { ReactComponent as CopyIcon } from '../../assets/icons/content_copy.svg';
import { css } from '@emotion/react';
import ConditionalTooltip from '../../components/Tooltip/ConditionalTooltip';
import { ADMIN_USERNAME, TESTSOLVE_USERNAME } from '../../data/puzzlehunt_details';
import AdminTable, { AllTeamsType } from './AdminTable';

const CSS_COPY_EMAILS_BUTTON = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '-12px',
    marginBottom: '24px',
});

const CSS_CENTERED_TEXT = css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
});

function Admin() {
    const [allTeams, setAllTeams] = useState<AllTeamsType>();
    const [isCopyTooltipVisible, setIsCopyTooltipVisible] = useState(false);
    const authUser = useAuthUser();
    const functions = useContext(FunctionsContext);

    const { callFunction: getAllTeamsFn, isRequestInProgress: isGetAllTeamsRequestInProgress } =
        useFirebaseFunction('getAllTeams');

    useEffect(() => {
        if (!isGetAllTeamsRequestInProgress && allTeams === undefined && authUser?.uid) {
            getAllTeamsFn({ username: authUser.uid })
                .then((getAllTeamsResponse) => {
                    setAllTeams(getAllTeamsResponse.data.allTeams);
                })
                .catch((error) => {
                    if ((error as FunctionsError).code === 'functions/not-found') {
                        setAllTeams([]);
                    } else {
                        console.error('Error while loading leaderboard:', error);
                        setAllTeams([]);
                    }
                });
        }
    }, [functions, allTeams, isGetAllTeamsRequestInProgress, authUser, getAllTeamsFn]);

    const { callFunction: getAllEmailsFn, isRequestInProgress: isGetAllEmailsRequestInProgress } =
        useFirebaseFunction('getAllEmails');

    const getAllEmails = useCallback(async () => {
        if (!isGetAllEmailsRequestInProgress && authUser?.uid) {
            try {
                const getAllEmailsResponse = await getAllEmailsFn({
                    username: authUser.uid,
                });
                navigator.clipboard.writeText(getAllEmailsResponse.data.emails.join(', '));
                setIsCopyTooltipVisible(true);
                setTimeout(() => {
                    setIsCopyTooltipVisible(false);
                }, 1000);
            } catch (error) {
                console.error('Error while trying to get all emails:', error);
            }
        }
    }, [authUser, getAllEmailsFn, isGetAllEmailsRequestInProgress]);

    const numTotalTeams = useMemo(() => {
        if (!allTeams) {
            return 0;
        }
        return allTeams.filter(({ username }) => {
            return username !== ADMIN_USERNAME && !username.includes(TESTSOLVE_USERNAME);
        }).length;
    }, [allTeams]);

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.ADMIN}</Title>
            {allTeams && numTotalTeams > 0 ? (
                <>
                    <div css={CSS_COPY_EMAILS_BUTTON}>
                        <b>Number teams: {numTotalTeams}</b>
                        <ConditionalTooltip tooltip={'Copied!'} showTooltip={isCopyTooltipVisible}>
                            <ActionButton
                                onClick={getAllEmails}
                                buttonStateOverride={
                                    authUser?.uid ? ButtonState.DEFAULT : ButtonState.DISABLED
                                }
                            >
                                <CopyIcon width="18px" height="18px" />
                                Copy all emails
                            </ActionButton>
                        </ConditionalTooltip>
                    </div>
                    <AdminTable allTeams={allTeams} />
                </>
            ) : (
                <div css={CSS_CENTERED_TEXT}>
                    {allTeams ? 'No teams registered yet.' : 'Loading...'}
                </div>
            )}
        </PageLayout>
    );
}

export default Admin;
