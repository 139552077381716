/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface SolvedMetaWrapperProps {
    children: React.ReactNode;
}

const CSS_FINISHED_META = css({
    display: 'flex',
});

function SolvedMetaWrapper(props: SolvedMetaWrapperProps) {
    const { children } = props;
    return <div css={CSS_FINISHED_META}>⏰🌎📜 {children} 📜🌎⏰</div>;
}

export default SolvedMetaWrapper;
