// TODO: Expose a UI to update this information in the DB.
export const PUZZLEHUNT_NAME = 'Holiday Hoopla';
export const CURRENT_YEAR = '2024';
export const PUZZLEHUNT_DATES = 'Dec 27-31';
export const MAX_TEAM_SIZE = 5;

export const META_SOLUTION_RESPONSE_TEXT =
    "You type {} into the console and -- pop! The time machine's clock resets to zero and you find yourself back in the present, with a very grateful Tick and Tock at your side. Congratulations on finishing the hunt!";
export const FEEDBACK_FORM_LINK = 'https://forms.gle/yMBBBd2WqbkBXuEk8';

// Hint values.
export const FEEDER_HINT_POINTS = 10;
export const META_HINT_POINTS = 20;

/**
 * The puzzlehunt state allows us to keep track of which pages are visible to everyone.
 * This is important because the same website is used every year, as opposed to other
 * puzzlehunts where a new website is created every year.
 */
export enum PuzzlehuntState {
    INACTIVE = 'Inactive',
    REGISTRATION_OPEN = 'Registration open',
    ACTIVE = 'Active',
    WRAP_UP = 'Post hunt wrap-up',
}
export const ALL_PUZZLEHUNT_STATES = Object.values(PuzzlehuntState);
export const REGISTRATION_STATES = [
    PuzzlehuntState.REGISTRATION_OPEN,
    PuzzlehuntState.ACTIVE,
    PuzzlehuntState.WRAP_UP,
];
export const ACTIVE_STATES = [PuzzlehuntState.ACTIVE, PuzzlehuntState.WRAP_UP];

/**
 * IMPORTANT: Make sure to convert the time to UTC before typing it here!
 * It's possible to use the current timezone, but it seems finicky and it's
 * better to use the UTC standard in case the hosted timezone changes somehow.
 *
 * You can use the following to confirm:
 * new Date(INSERT_TIME_HERE).toLocaleString("en-US", {timeZone: 'America/Chicago'})
 */
export const RELEASE_TIMES = {
    REGISTRATION_START: Date.UTC(2024, 9, 11, 17, 0, 0),
    // December 27, noon CST.
    START: Date.UTC(2024, 11, 27, 18, 0, 0),
    // December 27, 3PM CST.
    META: Date.UTC(2024, 11, 27, 21, 0, 0),
    // December 28, noon CST.
    FREEFORM_HINT_OPEN: Date.UTC(2024, 11, 28, 18, 0, 0),
    // December 31, noon CST.
    END: Date.UTC(2024, 11, 31, 18, 0, 0),
};

export const DISCORD_URL = 'https://discord.gg/U4RHmWfxRW';

export const HELP_EMAIL = 'viviandsisi.vitamincsquad@gmail.com';

export const ARCHIVED_YEARS = ['2023', '2022', '2021', '2020'];

// IMPORTANT: Reserves a username for the "admin" view of the page.
// This username will not show up in the leaderboard and will have
// access to all pages, regardless of whether the puzzlehunt is active.
export const ADMIN_USERNAME = 'admin';
export const TESTSOLVE_USERNAME = 'testsolver';
