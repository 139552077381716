/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import TableOfContents, { TableOfContentsType } from './TableOfContents';
import {
    ScreenSize,
    isSmallerThanOrEqual,
    useScreenSizeBreakpoints,
} from '../../hooks/useScreenSizeBreakpoints';
import { useCallback, useContext } from 'react';
import { AuthContext } from '../..';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import ActionButton, { ButtonType } from '../ActionButton/ActionButton';

const CSS_PAGE_OPTIONS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

interface PageWithTableOfContentsProps {
    children: React.ReactNode;
    contents: TableOfContentsType;
    /**
     * The text of the selected "table of contents" item.
     */
    selectedItem: string;
}

/**
 * Wrapper around the contents of a page which includes a "table of contents"
 * sidebar that can be used to toggle between page content.
 */
function PageWithTableOfContents(props: PageWithTableOfContentsProps) {
    const { children, contents, selectedItem } = props;
    const screenSize = useScreenSizeBreakpoints();

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const onLogout = useCallback(() => {
        try {
            signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error while logging out:', error);
        }
    }, [auth, navigate]);

    const hasVerticalLayout = isSmallerThanOrEqual(screenSize, ScreenSize.SMALL);
    const cssPageWithTableOfContents = css({
        alignItems: hasVerticalLayout ? 'center' : 'flex-start',
        display: 'flex',
        flexDirection: hasVerticalLayout ? 'column' : 'row',
        gap: '32px',
    });

    return (
        <div css={cssPageWithTableOfContents}>
            <div css={CSS_PAGE_OPTIONS}>
                <TableOfContents contents={contents} selectedItem={selectedItem} />
                <ActionButton width="100%" buttonType={ButtonType.PRIMARY} onClick={onLogout}>
                    Logout
                </ActionButton>
            </div>
            {children}
        </div>
    );
}

export default PageWithTableOfContents;
