import ActionButton, { ButtonType } from '../../../components/ActionButton/ActionButton';
import { ReactComponent as OpenInNewIcon } from '../../../assets/icons/open_in_new.svg';

interface OpenSolutionButtonProps {
    solutionLink: string;
}

function OpenSolutionButton(props: OpenSolutionButtonProps) {
    const { solutionLink } = props;

    return (
        <ActionButton width="200px" link={solutionLink} buttonType={ButtonType.SECONDARY}>
            <OpenInNewIcon width="18px" height="18px" />
            Open Solution
        </ActionButton>
    );
}

export default OpenSolutionButton;
