import ActionButton, { ButtonType } from '../../../components/ActionButton/ActionButton';
import { ReactComponent as OpenInNewIcon } from '../../../assets/icons/open_in_new.svg';

interface OpenSpreadsheetButtonProps {
    puzzleName: string;
    spreadsheetLink: string;
}

function OpenSpreadsheetButton(props: OpenSpreadsheetButtonProps) {
    const { puzzleName, spreadsheetLink } = props;

    return (
        <ActionButton
            width="200px"
            link={spreadsheetLink}
            ariaLabel={`Open ${puzzleName} spreadsheet (opens in a new tab)`}
            buttonType={ButtonType.SECONDARY}
        >
            <OpenInNewIcon width="18px" height="18px" />
            Spreadsheet
        </ActionButton>
    );
}

export default OpenSpreadsheetButton;
