import ActionButton, { ButtonType } from '../../../components/ActionButton/ActionButton';
import { convertToPathName, joinPaths } from '../../../utils/url';
import { PUZZLE_PAGE_PREPEND_URL } from '../../../data/page_structure';
import { ReactComponent as HintIcon } from '../../../assets/icons/hint.svg';

interface OpenHintsPageButtonProps {
    puzzleName: string;
}

function OpenHintsPageButton(props: OpenHintsPageButtonProps) {
    const { puzzleName } = props;

    return (
        <ActionButton
            width="200px"
            link={`${joinPaths(PUZZLE_PAGE_PREPEND_URL, convertToPathName(puzzleName))}/hints`}
            buttonType={ButtonType.SECONDARY}
        >
            <HintIcon width="18px" height="18px" />
            Hints Page
        </ActionButton>
    );
}

export default OpenHintsPageButton;
