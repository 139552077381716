import { PAGE_NAMES } from '../data/page_structure';
import ErrorPage from './ErrorPage';
import { useAuthUser } from '../hooks/useAuthUser';
import { isPageAccessible } from '../utils/flagging';

interface FlaggedPageProps {
    pageName: (typeof PAGE_NAMES)[keyof typeof PAGE_NAMES];
    children: React.ReactNode;
    adminOnly?: boolean;
    activePageOnly?: boolean;
    alternativeErrorText?: string;
}

function FlaggedPage(props: FlaggedPageProps) {
    const { pageName, children, adminOnly, activePageOnly, alternativeErrorText } = props;
    const authUser = useAuthUser();

    return isPageAccessible(authUser, pageName, adminOnly ?? false, activePageOnly ?? false) ? (
        <>{children}</>
    ) : (
        <ErrorPage text={alternativeErrorText} />
    );
}

export default FlaggedPage;
