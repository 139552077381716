export enum Sections {
    EASY = 'Easy',
    MEDIUM = 'Medium',
    HARD = 'Hard',
    META = 'Meta',
    WRAP_UP = 'Wrap-up',
}

interface BasePuzzleInfo {
    name: string;
    /**
     * The name of the puzzle as shown on the puzzles page.
     */
    displayName?: string;
    link: string;
    spreadsheetLink?: string;
    solutionLink?: string;
    isMeta?: boolean;
}

interface PuzzleWithCopyableStringInfo extends BasePuzzleInfo {
    /**
     * The copyable format-friendly string version of the puzzle.
     */
    formatFriendlyString: string;
}

interface PuzzleWithCopyableImageInfo extends BasePuzzleInfo {
    /**
     * The copyable image of the puzzle, if the puzzle is an image puzzle.
     */
    image: string;
}

export type PuzzleInfo =
    | BasePuzzleInfo
    | PuzzleWithCopyableStringInfo
    | PuzzleWithCopyableImageInfo;

export interface AllPuzzlesType {
    [puzzle: string]: PuzzleInfo;
}

export interface SectionType {
    name: Sections;
    points: number;
    puzzles: PuzzleInfo[];
}
export interface WrapUpType {
    solutions: string;
    leaderboard: string;
}
export interface SectionsType {
    [Sections.EASY]: SectionType;
    [Sections.MEDIUM]: SectionType;
    [Sections.HARD]: SectionType;
    [Sections.META]?: SectionType;
    [Sections.WRAP_UP]?: WrapUpType;
}
