/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import Row from '../../components/Form/Row';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import TextField from '../../components/TextField/TextField';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import { css, useTheme } from '@emotion/react';
import { signInWithCustomToken, updateProfile } from 'firebase/auth';
import {
    AccountPageType,
    CSS_ALIGN_RIGHT,
    INVALID_CHARACTER_REGEX,
    PASSWORD_MAX_LENGTH,
    TEAM_NAME_MAX_LENGTH,
    USERNAME_MAX_LENGTH,
    convertInvalidCharactersToErrorString,
} from './account_page_utils';
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import { FunctionsError } from 'firebase/functions';
import { useNavigate } from 'react-router';
import { AuthContext } from '../..';
import EditTeam from '../../components/EditTeam/EditTeam';
import {
    TeamMembersType,
    addErrorMessagesIfInvalid,
    getTeamMembersNameAndEmail,
    hasDuplicateEmails,
    hasEmailPresent,
} from '../../components/EditTeam/edit_team_requirements';
import Form from '../../components/Form/Form';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import ErrorPage from '../ErrorPage';
import { useAuthUser } from '../../hooks/useAuthUser';
import { CURRENT_YEAR } from '../../data/puzzlehunt_details';

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamNameErrorMessage, setTeamNameErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = useState('');
    const [teamMembers, setTeamMembers] = useState<TeamMembersType>([
        { name: '', email: '', nameErrorMessage: '', emailErrorMessage: '' },
    ]);
    const [addTeamMembersErrorMessage, setAddTeamMembersErrorMessage] = useState('');
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');

    const theme = useTheme();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const authUser = useAuthUser();

    const cssSubtitle = css({
        color: theme.colors.primary,
        fontWeight: 500,
        paddingBottom: '16px',
    });

    const checkErrors = () => {
        let hasError = false;
        if (!username) {
            setUsernameErrorMessage('Please enter a username.');
            hasError = true;
        }
        if (!teamName) {
            setTeamNameErrorMessage('Please enter a team name.');
            hasError = true;
        }
        if (!password) {
            setPasswordErrorMessage('Please enter a password.');
            hasError = true;
        }
        if (!confirmedPassword) {
            setConfirmedPasswordErrorMessage('Please re-enter your password.');
            hasError = true;
        } else if (password !== confirmedPassword) {
            setConfirmedPasswordErrorMessage("Passwords don't match.");
            hasError = true;
        }
        if (!hasEmailPresent(teamMembers)) {
            setAddTeamMembersErrorMessage('At least one email address is required.');
            hasError = true;
        } else if (hasDuplicateEmails(teamMembers)) {
            setAddTeamMembersErrorMessage('Email addresses must be unique.');
            hasError = true;
        } else {
            setAddTeamMembersErrorMessage('');
        }
        const { hasError: hasTeamMembersError, teamMembers: updatedTeamMembers } =
            addErrorMessagesIfInvalid(teamMembers);
        if (hasTeamMembersError) {
            hasError = true;
            setTeamMembers(updatedTeamMembers);
        }
        if (
            usernameErrorMessage ||
            teamNameErrorMessage ||
            passwordErrorMessage ||
            confirmedPasswordErrorMessage
        ) {
            hasError = true;
        }
        return hasError;
    };

    const { callFunction: registerFn, isRequestInProgress } = useFirebaseFunction('register');

    const register = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgress) {
            try {
                const registerResponse = await registerFn({
                    username: username,
                    displayName: teamName,
                    password: password,
                    teamMembers: getTeamMembersNameAndEmail(teamMembers),
                });
                const token = registerResponse.data.token;
                await signInWithCustomToken(auth, token);
                if (auth.currentUser) {
                    updateProfile(auth.currentUser, { displayName: teamName });
                }
                navigate('/account');
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/already-exists') {
                    const errorMessage = (error as FunctionsError).message;
                    if (errorMessage.toLowerCase().includes('username')) {
                        setUsernameErrorMessage(errorMessage);
                    } else if (errorMessage.toLowerCase().includes('team name')) {
                        setTeamNameErrorMessage(errorMessage);
                    } else {
                        setAddTeamMembersErrorMessage(errorMessage);
                    }
                } else if ((error as FunctionsError).code === 'functions/permission-denied') {
                    setUsernameErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while registering:', error);
                    setGeneralErrorMessage(
                        'There was an issue with registering your account. This may be due to restricted access to Google services in your region. Please contact viviandsisi.vitamincsquad@gmail.com for assistance.',
                    );
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgress;

    return authUser ? (
        <ErrorPage text="You are currently logged into an account.\nIf this is not your account, please log out in order to register a new account." />
    ) : (
        <PageLayout maxWidth="600px">
            <Title>{PAGE_NAMES.REGISTER}</Title>
            <div css={cssSubtitle}>
                Register for the {CURRENT_YEAR} Holiday Hoopla puzzlehunt! The account you register
                will only be active for the {CURRENT_YEAR} hunt.
            </div>
            <Form onSubmit={register} isDisabled={isButtonDisabled}>
                <Row>
                    <TextField
                        value={username}
                        setValue={(value) => {
                            setUsername(value);
                            if (value.length > USERNAME_MAX_LENGTH) {
                                setUsernameErrorMessage(
                                    `Please limit your username to ${USERNAME_MAX_LENGTH} characters or less.`,
                                );
                            } else if (INVALID_CHARACTER_REGEX.test(value)) {
                                setUsernameErrorMessage(
                                    `The following characters are not allowed: ${convertInvalidCharactersToErrorString()}`,
                                );
                            } else {
                                setUsernameErrorMessage('');
                            }
                        }}
                        label="Team Username"
                        description="Team username is a private ID, used for logging in. Please share this username with your team."
                        errorMessage={usernameErrorMessage}
                    />
                </Row>
                <Row>
                    <TextField
                        value={teamName}
                        setValue={(value) => {
                            setTeamName(value);
                            if (value.length > TEAM_NAME_MAX_LENGTH) {
                                setTeamNameErrorMessage(
                                    `Please limit your team name to ${TEAM_NAME_MAX_LENGTH} characters or less.`,
                                );
                            } else {
                                setTeamNameErrorMessage('');
                            }
                        }}
                        label="Team Name"
                        description="Team display name, which will be shown on public leaderboard."
                        errorMessage={teamNameErrorMessage}
                    />
                </Row>
                <Row>
                    <TextField
                        value={password}
                        setValue={(value) => {
                            setPassword(value);
                            if (value.length > PASSWORD_MAX_LENGTH) {
                                setPasswordErrorMessage(
                                    `Please limit your password to ${PASSWORD_MAX_LENGTH} characters or less.`,
                                );
                            } else {
                                setPasswordErrorMessage('');
                            }
                        }}
                        label="Team Password"
                        description="Please share this password with your team, and only your team."
                        isPassword
                        errorMessage={passwordErrorMessage}
                    />
                </Row>
                <Row>
                    <TextField
                        value={confirmedPassword}
                        setValue={(value) => {
                            setConfirmedPassword(value);
                            setConfirmedPasswordErrorMessage('');
                        }}
                        label="Confirm Team Password"
                        isPassword
                        errorMessage={confirmedPasswordErrorMessage}
                    />
                </Row>
                <h2 style={{ color: theme.colors.text.dark }}>Add Team Members</h2>
                <EditTeam
                    teamMembers={teamMembers}
                    setTeamMembers={setTeamMembers}
                    errorMessage={addTeamMembersErrorMessage}
                />
                {generalErrorMessage && (
                    <Row>
                        <div style={{ color: theme.colors.text.error }}>{generalErrorMessage}</div>
                    </Row>
                )}
                <div css={CSS_ALIGN_RIGHT}>
                    <ActionButton
                        // Hard-coding width so that the width of button stays the same when disabled.
                        width="120px"
                        buttonStateOverride={
                            isRequestInProgress ? ButtonState.DISABLED : ButtonState.DEFAULT
                        }
                        isFormSubmit={!isButtonDisabled}
                    >
                        {isRequestInProgress ? 'Registering' : AccountPageType.REGISTER}
                    </ActionButton>
                    <ConditionalLink link={PAGE_TO_URL[PAGE_NAMES.LOGIN]} showUnderline>
                        Already have an account? Log in.
                    </ConditionalLink>
                </div>
            </Form>
        </PageLayout>
    );
}

export default RegisterPage;
