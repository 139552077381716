/** @jsxImportSource @emotion/react */
import { FunctionsError } from 'firebase/functions';
import { useContext, useState } from 'react';
import Row from '../../components/Form/Row';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import TextField from '../../components/TextField/TextField';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import { css, useTheme } from '@emotion/react';
import { AccountPageType, CSS_ALIGN_RIGHT } from './account_page_utils';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import { AuthContext } from '../..';
import { useNavigate } from 'react-router';
import { signInWithCustomToken, updateProfile } from 'firebase/auth';
import Form from '../../components/Form/Form';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';

interface LoginProps {
    /**
     * If enabled, then this page only allows logging in and does not offer
     * the registration page. Intended use is only for admins of the page.
     */
    isAdmin?: boolean;
}

function LoginPage(props: LoginProps) {
    const { isAdmin } = props;
    const [username, setUsername] = useState('');
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');
    const theme = useTheme();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const cssForgotPasswordLink = css({
        fontSize: '15px',
        position: 'absolute',
        right: '0px',
        top: '0px',
    });

    const checkErrors = () => {
        let hasError = false;
        if (!username) {
            setUsernameErrorMessage('Please enter a username.');
            hasError = true;
        }
        if (!password) {
            setPasswordErrorMessage('Please enter a password.');
            hasError = true;
        }
        return hasError;
    };

    const { callFunction: loginFn, isRequestInProgress } = useFirebaseFunction('login');

    const login = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgress) {
            try {
                const loginResponse = await loginFn({
                    username: username,
                    password: password,
                });
                const token = loginResponse.data.token;
                await signInWithCustomToken(auth, token);
                if (auth.currentUser) {
                    updateProfile(auth.currentUser, {
                        displayName: loginResponse.data.teamName,
                    });
                }
                navigate('/account');
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/not-found') {
                    setUsernameErrorMessage((error as FunctionsError).message);
                } else if ((error as FunctionsError).code === 'functions/permission-denied') {
                    setPasswordErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while logging in:', error);
                    setGeneralErrorMessage(
                        'There was an issue with registering your account. This may be due to restricted access to Google services in your region. Please contact viviandsisi.vitamincsquad@gmail.com for assistance.',
                    );
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgress;

    return (
        <PageLayout maxWidth="400px">
            <Title>{isAdmin ? PAGE_NAMES.ADMIN_LOGIN : PAGE_NAMES.LOGIN}</Title>
            <Form onSubmit={login} isDisabled={isButtonDisabled}>
                <Row>
                    <TextField
                        value={username}
                        setValue={(value) => {
                            setUsername(value);
                            setUsernameErrorMessage('');
                        }}
                        label={isAdmin ? 'Admin Username' : 'Team Username'}
                        errorMessage={usernameErrorMessage}
                        autoFocus
                    />
                </Row>
                <Row>
                    <ConditionalLink
                        link={PAGE_TO_URL[PAGE_NAMES.FORGOT_PASSWORD]}
                        css={cssForgotPasswordLink}
                        tabIndex={1}
                        showUnderline
                    >
                        Forgot Password?
                    </ConditionalLink>
                    <TextField
                        value={password}
                        setValue={(value) => {
                            setPassword(value);
                            setPasswordErrorMessage('');
                        }}
                        label={isAdmin ? 'Admin Password' : 'Team Password'}
                        isPassword
                        errorMessage={passwordErrorMessage}
                    />
                </Row>
                {generalErrorMessage && (
                    <Row>
                        <div style={{ color: theme.colors.text.error }}>{generalErrorMessage}</div>
                    </Row>
                )}
                {
                    <div css={CSS_ALIGN_RIGHT}>
                        <ActionButton
                            // Hard-coding width so that the width of button stays the same when disabled.
                            width="120px"
                            buttonStateOverride={
                                isButtonDisabled ? ButtonState.DISABLED : ButtonState.DEFAULT
                            }
                            isFormSubmit={!isButtonDisabled}
                        >
                            {isRequestInProgress ? 'Logging in' : AccountPageType.LOGIN}
                        </ActionButton>
                        {!isAdmin && (
                            <ConditionalLink link={PAGE_TO_URL[PAGE_NAMES.REGISTER]} showUnderline>
                                Don't have an account? Register.
                            </ConditionalLink>
                        )}
                    </div>
                }
            </Form>
        </PageLayout>
    );
}

export default LoginPage;
