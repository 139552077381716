/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { convertToPathName, joinPaths } from '../../utils/url';
import ErrorPage from '../ErrorPage';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import { css, useTheme } from '@emotion/react';
import { useCurrentPuzzleSections } from '../../hooks/useCurrentPuzzleSections';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { PUZZLE_PAGE_PREPEND_URL } from '../../data/page_structure';
import {
    DISCORD_URL,
    FEEDER_HINT_POINTS,
    HELP_EMAIL,
    META_HINT_POINTS,
    RELEASE_TIMES,
} from '../../data/puzzlehunt_details';
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import { useUnlockHint } from '../../hooks/useUnlockHint';

const CSS_PUZZLE_HINTS_PAGE_CONTENTS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
});

const CSS_PUZZLE_HINT_CONTENT = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

const CSS_PREWRITTEN_HINT = css({
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid black',
});

function PuzzleHintsPage() {
    const { puzzleNameInUrl } = useParams();
    const [hintExists, setHintExists] = useState<boolean>();
    const [prewrittenHint, setPrewrittenHint] = useState<string>();
    const { allPuzzles } = useCurrentPuzzleSections();
    const authUser = useAuthUser();
    const theme = useTheme();

    const { isHintFree, setUsername, unlockHint, isUnlockHintsRequestInProgress } = useUnlockHint();

    const puzzleName = useMemo(() => {
        if (!puzzleNameInUrl) {
            return;
        }
        const matchingName = Object.values(allPuzzles).filter(({ name }) => {
            return convertToPathName(name) === convertToPathName(puzzleNameInUrl);
        })?.[0]?.name;
        return matchingName;
    }, [allPuzzles, puzzleNameInUrl]);

    const isMeta = useMemo(() => {
        if (!puzzleNameInUrl) {
            return;
        }
        return Object.values(allPuzzles).filter(({ name }) => {
            return convertToPathName(name) === convertToPathName(puzzleNameInUrl);
        })?.[0]?.isMeta;
    }, [allPuzzles, puzzleNameInUrl]);

    const pointValue = useMemo(() => {
        return isMeta ? META_HINT_POINTS : FEEDER_HINT_POINTS;
    }, [isMeta]);

    const {
        callFunction: getPuzzleHintsFn,
        isRequestInProgress: isGetPuzzleHintsRequestInProgress,
    } = useFirebaseFunction('getPuzzleHint');

    const loadHints = useCallback(
        (username: string) => {
            if (!isGetPuzzleHintsRequestInProgress && hintExists === undefined && puzzleName) {
                getPuzzleHintsFn({
                    username: username,
                    puzzleName: puzzleName,
                })
                    .then((response) => {
                        setHintExists(response.data.hintExists);
                        setPrewrittenHint(response.data.hint);
                    })
                    .catch((error) => {
                        console.error('Error while loading hints:', error);
                        setHintExists(false);
                    });
            }
        },
        [getPuzzleHintsFn, hintExists, isGetPuzzleHintsRequestInProgress, puzzleName],
    );

    useEffect(() => {
        if (authUser?.uid) {
            loadHints(authUser.uid);
            setUsername(authUser.uid);
        }
    }, [authUser, loadHints, setUsername]);

    return puzzleName ? (
        <PageLayout>
            <Title
                backButtonLink={joinPaths(PUZZLE_PAGE_PREPEND_URL, convertToPathName(puzzleName))}
            >
                {`${puzzleName} Hints`}
            </Title>
            <div css={CSS_PUZZLE_HINTS_PAGE_CONTENTS}>
                {hintExists === undefined && <>Loading...</>}
                {hintExists !== undefined &&
                    (!hintExists ? (
                        <p>
                            There are no pre-written hints for this puzzle. You can request a hint
                            from vitamincsquad on{' '}
                            <ConditionalLink link={DISCORD_URL} showUnderline>
                                Discord
                            </ConditionalLink>{' '}
                            or email us at{' '}
                            <ConditionalLink link={`mailto:${HELP_EMAIL}`} showUnderline>
                                {HELP_EMAIL}
                            </ConditionalLink>
                            . Please include your team username (<b>{authUser?.uid}</b>), the name
                            of the puzzle, and your current puzzle progress.
                        </p>
                    ) : (
                        <div css={CSS_PUZZLE_HINT_CONTENT}>
                            <p>
                                There is a pre-written hint for this puzzle.{' '}
                                {!prewrittenHint &&
                                    `Click on the button below to unlock it. ${
                                        isHintFree && !isMeta
                                            ? 'You have a hint voucher, so the next feeder puzzle hint you unlock will be free.'
                                            : `Please note that your first hint will cost ${pointValue} points.`
                                    }`}
                            </p>
                            {prewrittenHint ? (
                                <div
                                    css={CSS_PREWRITTEN_HINT}
                                    style={{ backgroundColor: theme.colors.background.light_pink }}
                                >
                                    <b>Prewritten hint: </b>
                                    {prewrittenHint}
                                </div>
                            ) : (
                                <ActionButton
                                    // Hard-coding width so that the width of button stays the same when disabled.
                                    width="266px"
                                    onClick={() => {
                                        if (authUser?.uid) {
                                            unlockHint(
                                                puzzleName,
                                                pointValue ?? FEEDER_HINT_POINTS,
                                                !!isMeta,
                                                (getSolvedPuzzlesResponse) => {
                                                    setPrewrittenHint(
                                                        getSolvedPuzzlesResponse.data.hint,
                                                    );
                                                },
                                                (error) => {
                                                    console.error(
                                                        'Error while loading hints:',
                                                        error,
                                                    );
                                                    setPrewrittenHint('');
                                                },
                                            );
                                        }
                                    }}
                                    buttonStateOverride={
                                        isUnlockHintsRequestInProgress
                                            ? ButtonState.DISABLED
                                            : ButtonState.DEFAULT
                                    }
                                >
                                    {isUnlockHintsRequestInProgress
                                        ? 'Unlocking hint'
                                        : `Unlock prewritten hint (-${
                                              isHintFree && !isMeta ? 0 : pointValue
                                          } pts)`}
                                </ActionButton>
                            )}
                            <p>
                                {Date.now() < RELEASE_TIMES.FREEFORM_HINT_OPEN ? (
                                    <>
                                        After 12PM CST on December 28th, freeform hints will be
                                        available. You can request a hint from{' '}
                                    </>
                                ) : (
                                    <>
                                        We understand that teams may get stuck at different points
                                        in a puzzle. If the prewritten hint doesn't resolve your
                                        issue, you can request freeform hints at{' '}
                                        <b>no extra cost</b>. To request a hint, message{' '}
                                    </>
                                )}
                                vitamincsquad on{' '}
                                <ConditionalLink link={DISCORD_URL} showUnderline>
                                    Discord
                                </ConditionalLink>{' '}
                                or email us at{' '}
                                <ConditionalLink link={`mailto:${HELP_EMAIL}`} showUnderline>
                                    {HELP_EMAIL}
                                </ConditionalLink>
                                . Please include your team username (<b>{authUser?.uid}</b>), the
                                name of the puzzle, and your current puzzle progress.
                            </p>
                        </div>
                    ))}
            </div>
        </PageLayout>
    ) : (
        <ErrorPage />
    );
}

export default PuzzleHintsPage;
