import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import Row from '../../components/Form/Row';
import { FEEDBACK_FORM_LINK } from '../../data/puzzlehunt_details';

function FeedbackFormPrompt() {
    return (
        <Row>
            <div>
                Thank you so much for participating in Holiday Hoopla! We hope you enjoyed the
                puzzlehunt! We're always looking for ways to improve, so we'd greatly appreciate it
                if you could share your feedback:{' '}
                <b>
                    <ConditionalLink link={FEEDBACK_FORM_LINK} showUnderline>
                        {FEEDBACK_FORM_LINK}
                    </ConditionalLink>
                    .
                </b>
            </div>
        </Row>
    );
}

export default FeedbackFormPrompt;
