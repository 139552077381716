/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import EditTeam from '../../../components/EditTeam/EditTeam';
import {
    TeamMembersType,
    addErrorMessagesIfInvalid,
    getTeamMembersNameAndEmail,
    hasDuplicateEmails,
    hasEmailPresent,
} from '../../../components/EditTeam/edit_team_requirements';
import ActionButton, { ButtonState } from '../../../components/ActionButton/ActionButton';
import { FunctionsError } from 'firebase/functions';
import { AuthContext } from '../../..';
import { css, useTheme } from '@emotion/react';
import { HELP_EMAIL } from '../../../data/puzzlehunt_details';
import Form from '../../../components/Form/Form';
import { useFirebaseFunction } from '../../../hooks/useFirebaseFunction';
import { useAuthUser } from '../../../hooks/useAuthUser';

const CSS_EDIT_TEAM_MEMBERS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

function EditTeamMembers() {
    const [teamMembers, setTeamMembers] = useState<TeamMembersType>();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const authUser = useAuthUser();
    const auth = useContext(AuthContext);
    const theme = useTheme();

    const { callFunction: getTeamMembersFn, isRequestInProgress: isRequestInProgressGetter } =
        useFirebaseFunction('getTeamMembers');
    const { callFunction: updateTeamMembersFn, isRequestInProgress: isRequestInProgressSetter } =
        useFirebaseFunction('updateTeamMembers');

    useEffect(() => {
        if (authUser) {
            if (!isRequestInProgressGetter && teamMembers === undefined) {
                getTeamMembersFn({ username: authUser.uid })
                    .then((getTeamMembersResponse) => {
                        const teamMembersData = getTeamMembersResponse.data.teamMembers;
                        setTeamMembers(teamMembersData);
                    })
                    .catch((error) => {
                        setErrorMessage(
                            `Something went wrong while trying to update your team. Please contact ${HELP_EMAIL} to resolve the issue.`,
                        );
                        console.error('Error while fetching team member data:', error);
                        setTeamMembers([]);
                    });
            }
        }
    }, [authUser, getTeamMembersFn, isRequestInProgressGetter, teamMembers]);

    const cssSuccessMessage = css({
        color: theme.colors.text.success,
    });

    const checkErrors = () => {
        if (teamMembers === undefined) {
            return true;
        }
        let hasError = false;
        if (!hasEmailPresent(teamMembers)) {
            setErrorMessage('At least one email address is required.');
            hasError = true;
        } else if (hasDuplicateEmails(teamMembers)) {
            setErrorMessage('Email addresses must be unique.');
            hasError = true;
        } else {
            setErrorMessage('');
        }
        const { hasError: hasTeamMembersError, teamMembers: updatedTeamMembers } =
            addErrorMessagesIfInvalid(teamMembers);
        if (hasTeamMembersError) {
            hasError = true;
            setTeamMembers(updatedTeamMembers);
        }
        return hasError;
    };

    const updateTeamMembers = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgressSetter && !!teamMembers) {
            try {
                if (auth.currentUser) {
                    await updateTeamMembersFn({
                        username: auth.currentUser.uid,
                        teamMembers: getTeamMembersNameAndEmail(teamMembers),
                    });
                    setSuccessMessage('Team members updated!');
                }
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/already-exists') {
                    setErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while editing team members:', error);
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgressSetter;

    return (
        <Form
            css={CSS_EDIT_TEAM_MEMBERS}
            onSubmit={updateTeamMembers}
            isDisabled={isButtonDisabled}
        >
            {teamMembers ? (
                <>
                    <div>
                        <EditTeam
                            teamMembers={teamMembers}
                            setTeamMembers={(updatedTeamMembers) => {
                                setTeamMembers(updatedTeamMembers);
                                setSuccessMessage('');
                            }}
                            errorMessage={errorMessage}
                        />
                    </div>
                    {successMessage && <div css={cssSuccessMessage}>{successMessage}</div>}
                    <ActionButton
                        // Hard-coding width so that the width of button stays the same when disabled.
                        width="110px"
                        buttonStateOverride={
                            isRequestInProgressSetter ? ButtonState.DISABLED : ButtonState.DEFAULT
                        }
                        isFormSubmit={!isButtonDisabled}
                    >
                        {isRequestInProgressSetter ? 'Saving' : 'Save Edits'}
                    </ActionButton>
                </>
            ) : (
                <>Loading...</>
            )}
        </Form>
    );
}

export default EditTeamMembers;
